/* You can add global styles to this file, and also import other style files */
.status-new-case {
    background-color: #FFD700; /* Gold */
    color: black;
  }
  
  .status-under-validation {
    background-color: #FF4500; /* Orange Red */
    color: white;
  }
  
  .status-verified {
    background-color: #32CD32; /* Lime Green */
    color: white;
  }
  
  .status-under-telecall {
    background-color: #1E90FF; /* Dodger Blue */
    color: white;
  }
  
  .status-interested {
    background-color: #8A2BE2; /* Blue Violet */
    color: white;
  }
  
  .status-not-interested {
    background-color: #FF1493; /* Deep Pink */
    color: white;
  }
  
  .status-client-enrolled {
    background-color: #20B2AA; /* Light Sea Green */
    color: white;
  }
  
  .status-field-visit {
    background-color: #FF6347; /* Tomato */
    color: white;
  }
  
  .status-default {
    background-color: #808080; /* Gray */
    color: white;
  }
  .dropdown-container select {
    border: none;
    outline: none;
    font-size: 14px;
    color: #333;
    background: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 10px;
    width: 51px!important;
}